<template>
  <div>
    <router-view style="padding: 8px" />
  </div>
</template>

<script>
export default {
  components: {},
  computed: {},
  data() {
    return {};
  },
};
</script>

<style scoped></style>
